import { useInfiniteQuery } from "@tanstack/react-query";
import axios from "axios";

import { useToast } from "@/app/core/display/toast/store/useToast";
import type { UseInfiniteApiQuery } from "@/app/core/http/api-client.types";
import { useApiClient } from "@/app/core/http/hooks/useApiClient";
import { config } from "@/app/core/utils/config";
import { applyPathParams, routeManifest } from "@obd/api-client";
import type { Paginated } from "@obd/common";

export const useInfiniteApiQuery: UseInfiniteApiQuery = (key, options?) => {
  const { query } = useApiClient();

  return {
    ...useInfiniteQuery(
      typeof key === "string" ? [key] : key,
      ({ pageParam }) => {
        const fn = query[typeof key === "string" ? key : key[0]] as any;

        return fn({
          ...(options as any)?.request,
          query: {
            ...(options as any)?.request?.query,
            cursor: pageParam,
          },
        });
      },
      {
        getNextPageParam: (last: Paginated<any[]>) => last?.meta?.cursor,
        meta: options?.exportable ? {} : undefined,
        ...(options as any),
      }
    ),
    meta: {
      exportQueryAs: options?.exportable
        ? (format, columns) => {
            const queryKey = typeof key === "string" ? key : key[0];
            let path = routeManifest[queryKey].path as string;
            const params = (options as any)?.request?.params as Record<string, string>;
            if (params) {
              path = applyPathParams(path, params);
            }

            const uri = axios.getUri({
              url: path,
              params: {
                ...(options as any)?.request?.query,
                exportFormat: format,
                columns: columns && JSON.stringify(columns),
              },
            });

            const redirectUrl = config.apiBaseUrl + uri;

            const a = document.createElement("a");
            a.href = redirectUrl;
            a.setAttribute("target", "_blank");
            a.click();

            useToast
              .getState()
              .showToast({ type: "success", message: "Tabla exportada" });
          }
        : undefined,
    },
  };
};
