import { useCallback } from "react";

import type { RefetchOptions, RefetchQueryFilters } from "@tanstack/react-query";
import { useQueryClient } from "@tanstack/react-query";

import type { ComposedQueryKey, QueryMethods } from "@/app/core/http/api-client.types";

export const useApiRefetch = () => {
  const queryClient = useQueryClient();

  return useCallback(
    <K extends QueryMethods>(
      key: K | ComposedQueryKey<K>,
      filters?: RefetchQueryFilters,
      options?: RefetchOptions
    ) => {
      queryClient.refetchQueries(typeof key === "string" ? [key] : key, filters, options);
    },
    [queryClient]
  );
};
