import { useCallback, useEffect, useState } from "react";

import { useLocalStorageValue } from "@react-hookz/web";

export const useFiltersStorage = <T>(
  key: string,
  {
    useLocalStorage = false,
    initialValue,
    cast,
  }: { useLocalStorage?: boolean; initialValue?: T; cast?: (v: any) => T }
) => {
  const [isLoading, setIsLoading] = useState(useLocalStorage);
  const [stateValue, setStateValue] = useState<T | undefined>(() => initialValue);
  const { value: storageValue, set: setStorageValue } = useLocalStorageValue<T>(
    `${key}-datatable-filters`,
    {
      initializeWithValue: false,
    }
  );

  useEffect(() => {
    if (useLocalStorage) {
      setIsLoading(storageValue === undefined);
    }
  }, [storageValue, useLocalStorage]);

  useEffect(() => {
    if (useLocalStorage && initialValue !== undefined && storageValue === null) {
      setStorageValue(initialValue);
    }
  }, [initialValue, setStorageValue, storageValue, useLocalStorage]);

  const setValue = useCallback(
    (value: T) => {
      if (useLocalStorage) {
        setStorageValue(cast ? cast(value) : value);
      } else {
        setStateValue(cast ? cast(value) : value);
      }
    },
    [cast, setStorageValue, useLocalStorage]
  );

  if (useLocalStorage) {
    return [storageValue, setValue, isLoading] as const;
  }

  return [stateValue, setValue, isLoading] as const;
};
