import { useCallback, useEffect, useMemo, useState } from "react";

import type {
  ColumnOrderState,
  ColumnSizingState,
  SortingState,
} from "@tanstack/react-table";
import type { z } from "zod";

import type { InitialColumnState } from "@/app/core/datatable/datatable.types";
import { useFiltersStorage } from "@/app/core/datatable/filters/hooks/useFiltersStorage";
import { useToast } from "@/app/core/display/toast/store/useToast";
import type { QueryMethods } from "@/app/core/http/api-client.types";
import { useApiClient } from "@/app/core/http/hooks/useApiClient";
import { useApiQuery } from "@/app/core/http/hooks/useApiQuery";
import { useApiRefetch } from "@/app/core/http/hooks/useApiRefetch";
import { config } from "@/app/core/utils/config";
import type {
  ClientFacets,
  Filters,
  ITableView,
  PrismaTableViewEntity,
  TableFilters,
  filterSchema,
} from "@obd/common";

interface UseTableFiltersOptions {
  viewEntity?: PrismaTableViewEntity;
  filtersQuery?: QueryMethods;
  params?: Partial<Pick<Filters, "sort" | "limit">>;
  persistKey?: string;
  exportQuery?: QueryMethods;
}

export interface UseTableFiltersResult<T> {
  queryKey: string;
  isLoading: boolean;
  setSearch: (term: string) => void;
  filters?: TableFilters<T>;
  params: z.infer<(typeof filterSchema)["query"]>;
  columnState: Partial<InitialColumnState>;
  activeFacets: ClientFacets;
  setActiveFacets: React.Dispatch<React.SetStateAction<ClientFacets>>;
  views?: {
    items: ITableView[];
    openedViews: number[];
    openView: (id: number) => void;
    closeView: (id: number) => void;
    deleteView: (id: number) => void;
    activeViewId: number;
    setActiveViewId: (id: number) => void;
    createTableView: (name: string) => void;
    updateTableView: (
      id: number,
      data: z.infer<(typeof filterSchema)["updateTableView"]>
    ) => void;
    updateCurrentView: () => Promise<void>;
  };
}

export const useTableFilters = <T>(
  options?: UseTableFiltersOptions
): UseTableFiltersResult<T> => {
  const [activeFacets, setActiveFacets] = useState<ClientFacets>([]);

  /**
   * The general tab. Non-filtered, non-editable
   */
  const generalView: ITableView | undefined = useMemo(
    () =>
      options?.viewEntity && {
        id: 0,
        entity: options.viewEntity,
        name: "General",
        version: config.filtersVersion,
        isStandard: true,
        columns: { order: [], sizing: {}, visibility: {} },
        facets: [],
      },
    [options?.viewEntity]
  );

  /**
   * Current active tab
   */
  const [activeViewId, setActiveViewId, isActiveViewLoading] = useFiltersStorage<
    ITableView["id"]
  >([options?.persistKey, options?.filtersQuery, "view"].filter(Boolean).join("-"), {
    useLocalStorage: true,
    initialValue: generalView?.id,
    cast: (v) => Number(v),
  });

  /**
   * Current opened tabs
   */
  const [openedViews, setOpenedViews, isOpenedViewsLoading] = useFiltersStorage<number[]>(
    [options?.persistKey, options?.filtersQuery, "open-views"].filter(Boolean).join("-"),
    {
      useLocalStorage: true,
      initialValue: generalView && [generalView.id],
    }
  );

  /**
   * Open a new tab
   */
  const openView = useCallback(
    (id: number) => {
      if (!openedViews) {
        setOpenedViews([id]);
        setActiveViewId(id);
        return;
      }

      if (openedViews.length >= 5) {
        useToast.getState().showToast({
          type: "info",
          message:
            "Has alcanzado el límite de vistas abiertas. Cierra una para añadir más.",
        });

        return;
      }

      if (openedViews.some((v) => v === id)) {
        setActiveViewId(id);
        return;
      }

      setOpenedViews([...openedViews, id]);

      setActiveViewId(id);
    },
    [openedViews, setActiveViewId, setOpenedViews]
  );

  /**
   * Close an opened view
   */
  const closeView = useCallback(
    (id: number) => {
      if (!openedViews) {
        return;
      }

      if (openedViews.length <= 1) {
        useToast
          .getState()
          .showToast({ type: "info", message: "No puedes cerrar todas las vistas" });
        return;
      }

      if (activeViewId === id) {
        setActiveViewId(openedViews?.[0]);
      }

      setOpenedViews(openedViews.filter((v) => v !== id));
    },
    [activeViewId, openedViews, setActiveViewId, setOpenedViews]
  );

  /**
   * Prevent search term from being lost on page change
   */
  const [search, setSearch] = useState<string>();

  /**
   * Get available filters for this table
   */
  const { data: filters, isInitialLoading: isFiltersLoading } = useApiQuery(
    (options?.filtersQuery ?? ("noop" as any)) as "getLeadFilters",
    {
      enabled: Boolean(options?.filtersQuery),
      keepPreviousData: true,
      staleTime: Infinity,
    }
  );

  /**
   * Get user views for this table
   */
  const { data: views, isInitialLoading: isViewsLoading } = useApiQuery(
    ["getTableViews", options?.viewEntity ?? "noop", config.filtersVersion],
    {
      request: {
        query: {
          version: config.filtersVersion,
          entity: options?.viewEntity ?? ("noop" as any),
        },
      },
      enabled: Boolean(options?.viewEntity),
      keepPreviousData: true,
    }
  );

  /**
   * Table columns config
   */
  const [columnVisibility, setColumnVisibility] = useState<Record<string, boolean>>({});
  const [columnSizing, setColumnSizing] = useState<ColumnSizingState>({});
  const [columnOrder, setColumnOrder] = useState<ColumnOrderState>([]);
  const [columnSorting, setColumnSorting] = useState<SortingState>(
    // eslint-disable-next-line no-nested-ternary
    options?.viewEntity
      ? []
      : options?.params?.sort?.entity
      ? [
          {
            id: options.params.sort.entity,
            desc: options.params.sort.direction === "desc",
          },
        ]
      : []
  );

  const [isLoadingColumnState, setIsLoadingColumnState] = useState(true);

  useEffect(() => {
    if (!generalView || generalView.id === activeViewId || !views?.length) {
      setIsLoadingColumnState(false);
      return;
    }

    const activeView = views.find((v) => v.id === activeViewId);
    if (activeView) {
      setColumnVisibility(activeView.columns.visibility ?? {});
      setColumnSizing(activeView.columns.sizing ?? {});
      setColumnOrder(activeView.columns.order ?? []);
      setColumnSorting(activeView.columns.sorting ?? []);
    }

    setIsLoadingColumnState(false);
  }, [activeViewId, generalView, views]);

  useEffect(() => {
    if (options?.params?.sort && !isLoadingColumnState && columnSorting.length === 0) {
      setColumnSorting([
        {
          id: options.params.sort.entity,
          desc: options.params.sort.direction === "desc",
        },
      ]);
    }
  }, [columnSorting.length, isLoadingColumnState, options?.params?.sort]);

  const client = useApiClient();
  const refetch = useApiRefetch();

  /**
   * Create a new table view
   */
  const createTableView = useCallback(
    async (name: string) => {
      if (!options?.viewEntity) {
        return;
      }

      const res = await client.mutation.createTableView({
        body: {
          name,
          entity: options.viewEntity,
          version: config.filtersVersion,
          columns: {
            visibility: columnVisibility,
            order: columnOrder,
            sizing: columnSizing,
            sorting: columnSorting,
          },
          facets: activeFacets,
        },
      });

      refetch(["getTableViews", options?.viewEntity ?? "noop", config.filtersVersion]);

      useToast.getState().showToast({
        type: "success",
        message: `Vista '${name}' creada`,
      });

      openView(res.id);
    },
    [
      activeFacets,
      client.mutation,
      columnOrder,
      columnSizing,
      columnSorting,
      columnVisibility,
      openView,
      options?.viewEntity,
      refetch,
    ]
  );

  /**
   * Update a table view
   */
  const updateTableView = useCallback(
    async (id: number, data: z.infer<(typeof filterSchema)["updateTableView"]>) => {
      if (!options?.viewEntity) {
        return;
      }

      if (id === generalView?.id) {
        useToast.getState().showToast({
          type: "info",
          message:
            "No puedes actualizar la vista 'General'. Crea una nueva si deseas personalizarla.",
        });

        return;
      }

      await client.mutation.updateTableView({
        params: { viewId: id },
        body: {
          ...data,
          version: config.filtersVersion,
          facets: activeFacets,
          columns: {
            visibility: columnVisibility,
            order: columnOrder,
            sizing: columnSizing,
            sorting: columnSorting,
          },
        },
      });

      refetch(["getTableViews", options?.viewEntity ?? "noop", config.filtersVersion]);

      useToast.getState().showToast({
        type: "success",
        message: `Vista actualizada`,
      });
    },
    [
      activeFacets,
      client.mutation,
      columnOrder,
      columnSizing,
      columnSorting,
      columnVisibility,
      generalView?.id,
      options?.viewEntity,
      refetch,
    ]
  );

  /**
   * Delete a view
   */
  const deleteView = useCallback(
    async (id: number) => {
      await client.mutation.deleteTableView({
        params: { viewId: id },
      });

      refetch(["getTableViews", options?.viewEntity ?? "noop", config.filtersVersion]);

      if (openedViews) {
        setOpenedViews(openedViews.filter((v) => v !== id));
      }

      useToast.getState().showToast({
        type: "info",
        message: `Vista eliminada`,
      });
    },
    [client.mutation, openedViews, options?.viewEntity, refetch, setOpenedViews]
  );

  /**
   * Update the current active view with
   * the active facets and columns
   */
  const updateCurrentView = useCallback(async () => {
    if (activeViewId === undefined) {
      return;
    }

    await updateTableView(activeViewId, {});
  }, [activeViewId, updateTableView]);

  /**
   * Set a valid active view
   */
  useEffect(() => {
    if (
      (options?.viewEntity &&
        activeViewId === undefined &&
        !isActiveViewLoading &&
        generalView) ||
      (views &&
        activeViewId !== undefined &&
        generalView &&
        activeViewId !== generalView.id &&
        !views.some((v) => v.id === activeViewId))
    ) {
      setActiveViewId(generalView.id);
    }
  }, [
    activeViewId,
    generalView,
    isActiveViewLoading,
    options?.viewEntity,
    setActiveViewId,
    views,
  ]);

  /**
   * Set active facets to match active view
   */
  useEffect(() => {
    if (generalView && activeViewId === generalView.id) {
      setActiveFacets(generalView.facets);
    } else if (activeViewId !== undefined) {
      const match = views?.find((v) => v.id === activeViewId);
      if (match) {
        setActiveFacets(match.facets);
      }
    }
  }, [activeViewId, generalView, views]);

  /**
   * Compute the query key
   */
  const queryKey = useMemo(() => {
    const items: string[] = [];

    if (options?.params?.limit) {
      items.push(`limit:${options.params.limit}`);
    }

    if (columnSorting[0] || options?.params?.sort) {
      const e = columnSorting[0]?.id || options?.params?.sort?.entity;
      const s = columnSorting[0]
        ? columnSorting[0].desc === true
          ? "desc"
          : "asc"
        : options?.params?.sort?.direction;

      items.push(`sort:${e}:${s || "asc"}`);
    }

    if (search) {
      items.push(`search:${search}`);
    }

    if (activeFacets.length) {
      items.push(`facets:${JSON.stringify(activeFacets)}`);
    }

    return items.join("|");
  }, [
    activeFacets,
    columnSorting,
    options?.params?.limit,
    options?.params?.sort,
    search,
  ]);

  const params = useMemo<z.input<(typeof filterSchema)["query"]>>(
    () => ({
      sort: options?.params?.sort?.entity,
      order: options?.params?.sort?.direction,
      limit: options?.params?.limit,
      search,
      facets: JSON.stringify(activeFacets),
    }),
    [
      activeFacets,
      options?.params?.limit,
      options?.params?.sort?.direction,
      options?.params?.sort?.entity,
      search,
    ]
  );

  return {
    queryKey,
    isLoading:
      isFiltersLoading ||
      isViewsLoading ||
      isOpenedViewsLoading ||
      isActiveViewLoading ||
      isLoadingColumnState,
    setSearch,
    params: {
      ...params,
      sort: columnSorting[0]?.id,
      order: columnSorting[0]?.desc ? "desc" : "asc",
    } as unknown as z.infer<(typeof filterSchema)["query"]>,
    activeFacets,
    setActiveFacets,
    filters: filters as TableFilters<T> | undefined,
    columnState: {
      columnOrder,
      setColumnOrder,
      columnSizing,
      setColumnSizing,
      columnVisibility,
      setColumnVisibility,
      columnSorting,
      setColumnSorting,
      isLoading: isLoadingColumnState,
    },
    views:
      !views ||
      !options?.viewEntity ||
      !generalView ||
      !openedViews ||
      activeViewId === undefined
        ? undefined
        : {
            items: [generalView, ...views],
            activeViewId,
            setActiveViewId,
            openedViews,
            openView,
            closeView,
            deleteView,
            createTableView,
            updateTableView,
            updateCurrentView,
          },
  };
};
